<template>
  <v-card>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row dense>
            <v-col class="form-group-header" style="margin-top: 0" cols="12">
              <h3 class="text-h6">{{ formTitle }}</h3>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.title"
                :error-messages="backendErrors.title"
                name="title"
                :label="getRequiredFieldLabel($t('title'))"
                required
                @blur="clearErrors('title')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <app-contact-autocomplete
                v-if="dialog"
                :searchRoute="clientSearchRoute"
                :item="item.client"
                :errorMessages="backendErrors.client_id"
                :labelText="$t('contact')"
                textField="name"
                clearable
                @itemSelected="setClient"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <BaseDatepickerInput
                v-model="item.estimated_beginning_at"
                :error-messages="backendErrors['estimated_beginning_at']"
                :label="$t('projects.labels.estimated_start')"
                @blur="clearErrors('estimated_start')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <BaseDatepickerInput
                v-model="item.deadline"
                :error-messages="backendErrors['deadline']"
                :label="$t('projects.labels.deadline')"
                @blur="clearErrors('deadline')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.estimated_duration"
                :error-messages="backendErrors.estimated_duration"
                name="estimated_duration"
                :label="$t('projects.labels.estimated_duration')"
                @blur="clearErrors('estimated_duration')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.estimated_sum"
                :error-messages="backendErrors.estimated_sum"
                name="estimated_sum"
                :label="$t('projects.labels.estimated_sum')"
                @blur="clearErrors('estimated_sum')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="item.status"
                :items="statuses"
                :error-messages="backendErrors['status']"
                name="status"
                :label="getRequiredFieldLabel($t('status'))"
                required
                @blur="clearErrors('status')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.sentry_name"
                :error-messages="backendErrors.sentry_name"
                name="sentry_name"
                :label="$t('projects.labels.sentry_name')"
                @blur="clearErrors('sentry_name')"
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="item.description"
                :error-messages="backendErrors.description"
                name="description"
                :label="$t('projects.labels.description')"
                auto-grow
                @blur="clearErrors('initial_value')"
              />
            </v-col>
          </v-row>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="item.is_private"
              :error-messages="backendErrors.is_private"
              name="is_private"
              :label="$t('projects.labels.is_private')"
              class="form-checkbox"
              @blur="clearErrors('is_private')"
            />
          </v-col>

          <v-row >
            <v-col class="form-group-header" cols="12">
              <h3 class="text-h6">{{ $t('projects.general.assign_users') }}</h3>
            </v-col>
            <template v-for="(assignee, index) of item.assignees">
              <v-col cols="12" sm="6">
                <app-client-user-autocomplete
                  v-if="dialog"
                  :searchRoute="userSearchRoute"
                  :disabled="assignee.permission === 'creator'"
                  :item="assignee"
                  :hintText="$t('search_by_name')"
                  :clearable="true"
                  :labelText="$t('user')"
                  textField="personal_data.full_name"
                  valueField="user_id"
                  @itemSelected="setAssignee($event, index)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  :value="assignee.permission"
                  :items="permissionTypes"
                  :disabled="assignee.permission === 'creator'"
                  :label="$t('projects.labels.permissions')"
                  @change="setPermission($event, index)"
                />
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        text
        @click="addAssignee"
      >
        {{ $t('projects.labels.add_user') }}
      </v-btn>
      <v-spacer/>
      <v-btn
        text
        @click.native="close"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click.native="save(apiRoute)"
        :disabled="requestPending"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ContactAutocomplete from './form-fields/ContactAutocomplete.vue';
import ClientUserAutocomplete from './form-fields/ClientUserAutocomplete.vue';
import formMixin from './mixins/form-mixin';
import dateUtilsMixin from './mixins/date-utils-mixin';
import BaseDatepickerInput from '../base/BaseDatepickerInput';

export default {
  name: 'ProjectForm',

  components: {
    BaseDatepickerInput,
    appContactAutocomplete: ContactAutocomplete,
    appClientUserAutocomplete: ClientUserAutocomplete,
  },

  mixins: [formMixin, dateUtilsMixin],

  data() {
    return {
      estimatedBeginningAtPicker: false,
      deadlinePicker: false,
      clientSearchRoute: 'api/clients/find/',
      userSearchRoute: 'api/users/find/',
      apiRoute: 'api/projects/',
      backendErrors: {
        client_id: [],
        title: [],
        estimated_beginning_at: [],
        deadline: [],
        estimated_duration: [],
        estimated_sum: [],
        status: [],
        sentry_name: [],
        description: [],
        is_private: [],
      },
    };
  },

  computed: {
    formTitle() {
      return this.index === -1
        ? this.$t('new_project')
        : this.$t('projects.modal_titles.edit_project');
    },

    statuses() {
      return [
        { value: 'planned', text: this.$t('projects.project_status.planned') },
        { value: 'active', text: this.$t('projects.project_status.active') },
        { value: 'support', text: this.$t('projects.project_status.support') },
        { value: 'ended', text: this.$t('projects.project_status.ended') },
      ];
    },

    permissionTypes() {
      return [
        { text: this.$t('projects.assignee_permissions.creator'), value: 'creator', disabled: true },
        { text: this.$t('projects.assignee_permissions.read'), value: 'read' },
        { text: this.$t('projects.assignee_permissions.write'), value: 'write' },
      ];
    },
  },

  methods: {
    save(apiRoute) {
      const requestBody = JSON.parse(JSON.stringify(this.item));
      const count = requestBody.assignees.length;
      requestBody.assignees = [];
      for (let i = 0; i < count; i++) {
        if (this.item.assignees[i].user_id && this.item.assignees[i].permission) {
          requestBody.assignees.push({
            user_id: this.item.assignees[i].user_id,
            permission: this.item.assignees[i].permission,
          });
        }
      }
      this.requestPending = true;
      if (this.index > -1) {
        this.$http.put(`${apiRoute}${this.item.id}`, requestBody).then((response) => {
          this.$emit('itemUpdated', response.data);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      } else {
        this.$http.post(`${apiRoute}`, requestBody).then((response) => {
          this.$emit('itemCreated', response.data);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      }
    },

    setClient(client) {
      if (client) {
        this.item.client = client;
        this.item.client_id = client.id;
      } else {
        this.item.client = null;
        this.item.client_id = null;
      }
    },

    addAssignee() {
      this.item.assignees.push({ permission: 'write' });
    },

    setAssignee(assignee, index) {
      if (assignee) {
        const permission = this.item.assignees[index].permission;
        assignee.user_id = assignee.id;
        this.item.assignees[index] = assignee;
        if (assignee.client_id) {
          assignee.permission = 'read';
        } else {
          assignee.permission = permission;
        }
        this.item.assignees.splice(index, 1, assignee);
      } else {
        this.item.assignees[index] = {};
      }
    },

    setPermission(value, index) {
      this.item.assignees[index].permission = value;
    },
  },
};
</script>

<style scoped>

</style>
