<template>
  <v-autocomplete
    :search-input.sync="search"
    :error-messages="displayedErrors"
    :loading="loading"
    :disabled="disabled"
    :hint="hintText"
    :label="labelText"
    :item-text="textField"
    :items="items"
    :item-value="valueField"
    :clearable="clearable"
    :append-icon="appendedIcon"
    v-model="selectedItem"
    return-object
    hide-no-data
    hide-selected
    no-filter
    @click:append="appendedIconClicked"
    @input="emitData"
    @keyup="typing = true"
  >
    <template
      slot="selection"
      slot-scope="data"
    >
      <span>{{ data.item.personal_data.full_name }}</span>
      <template v-if="data.item.client">
        &nbsp;({{ data.item.client.name }})
      </template>
    </template>
    <template
      slot="item"
      slot-scope="data"
    >
      <v-list-item-content>
        <v-list-item-title>
          <span>{{ data.item.personal_data.full_name }}</span>
          <template v-if="data.item.client">
            ({{ data.item.client.name }})
          </template>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ClientUserAutocomplete',

  props: {
    labelText: String,
    textField: String,
    item: Object,
    valueField: {
      type: String,
      default: 'id',
    },
    hintText: {
      type: String,
      default: '',
    },
    searchRoute: String,
    errorMessages: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    appendedIcon: {
      type: String,
      default: 'mdi-menu-down',
    },
  },

  data() {
    return {
      displayedErrors: [],
      items: [],
      typing: false,
      loading: false,
      search: null,
      timer: null,
      selectedItem: {},
    };
  },

  watch: {
    search(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        val && val.length > 2 && this.searchItems(val);
      }, 500);
    },

    item() {
      if (this.item) {
        this.selectedItem = { ...this.item };
        this.items = [];
        this.items.push(this.item);
      } else {
        this.selectedItem = {};
        this.items = [];
      }
    },

    errorMessages(val) {
      this.displayedErrors = val;
    },
  },

  created() {
    if (this.item && this.item[this.valueField]) {
      this.selectedItem = { ...this.item } || {};
      this.items.push(this.item);
    }
  },

  methods: {
    emitData() {
      this.typing = false;
      this.displayedErrors = [];
      this.$emit('itemSelected', this.selectedItem);
    },

    searchItems(v) {
      if (v.length > 2 && this.typing) {
        this.loading = true;
        this.$http.get(`${this.searchRoute}${v}`).then((resp) => {
          this.items = resp.data.data;
          this.loading = false;
        });
      }
    },

    appendedIconClicked() {
      this.$emit('appendedIconClicked');
    },
  },
};
</script>
