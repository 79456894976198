<template>
  <BaseLoadingOverlay :loading="$store.state.projects.isLoaderDisplayed">
    <v-container v-if="!loading" style="margin-bottom: 90px !important;" fluid>
      <v-row align="center" dense>
        <v-col style="min-height: 48px;" cols="12" md="6">
          <span class="text-h6 pl-3">
            {{ projectTitle }}
          </span>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mx-0 title-btn"
              icon
              @click.stop="editProject"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-col>

        <v-col class="text-md-right pl-3 align-center" cols="12" md="6">
          <div v-if="selectedUserStoryCount > 0
            && $store.state.projects.selectingUserStories
            && $store.getters.projectPermission === 'creator'"
          >
            <span class="text-h6">
              {{ `${this.$t('projects.general.user_stories_chosen')}: ${selectedUserStoryCount}` }}
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="mx-0 title-btn"
                  icon
                  @click.stop="$store.state.projects.userStoryMoveDialog = true"
                >
                  <v-icon>mdi-forward</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('projects.controls.move_user_stories') }}
              </span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <v-tabs :value="activeTab" show-arrows>
        <v-tab @click="getBacklog(0)">
          {{ $t('projects.general.job_list') }}
        </v-tab>

        <v-tab @click="getBacklog(1)">
          {{ $t('team') }}
        </v-tab>

<!--        <v-tab @click="$router.push({ name: 'project-statistics' }).catch(() => {})">-->
<!--          {{ $t('statistics') }}-->
<!--        </v-tab>-->
      </v-tabs>

      <router-view/>

      <!-- Project edit dialog form -->
      <v-dialog
        v-model="$store.state.projects.projectEditDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="800px"
        transition="slide-y-reverse-transition"
        scrollable
        persistent
      >
        <app-project-form
          :index="1"
          :item="$store.state.projects.editedItem"
          :dialog="$store.state.projects.projectEditDialog"
          @itemUpdated="projectUpdated"
          @dialogClosed="$store.state.projects.projectEditDialog = false"
        />
      </v-dialog>
    </v-container>
  </BaseLoadingOverlay>
</template>

<script>
import ProjectForm from './ProjectForm.vue';
import BaseLoadingOverlay from '../base/BaseLoadingOverlay';

export default {
  name: 'ProjectWrapper',

  components: {
    BaseLoadingOverlay,
    appProjectForm: ProjectForm,
  },

  data() {
    return {
      loading: true,
      projectTitle: '',
      activeTab: 0,
    };
  },

  computed: {
    selectedUserStoryCount() {
      let count = 0;
      Object.values(this.$store.getters.selectedUserStories).forEach((val) => {
        if (val) {
          count += 1;
        }
      });
      return count;
    },
  },

  created() {
    this.getProjectTitle();
    if (this.$store.getters.currentUser?.role === 'admin') {
      this.$store.commit('setProjectPermission', 'creator');
    }
    switch (this.$route.name) {
      case 'project-backlog':
        this.activeTab = 0;
        this.getBacklog(0);
        break;
      case 'project-team':
        this.activeTab = 1;
        this.getBacklog(1); // team and backlog are kind of linked together
        break;
      case 'project-statistics':
        this.loading = false;
        this.activeTab = 2;
        break;
      default:
        this.getBacklog();
        break;
    }
    this.$store.state.selectingUserStories = false;
    this.$store.state.selectedUserStories = {};
  },

  destroyed() {
    this.$store.commit('storeProject', {});
  },

  methods: {
    editProject() {
      this.$store.commit('openProjectEditForm');
    },

    getBacklog(clickedTabIndex) {
      this.$store.dispatch('showTopProgress');
      this.$http.get(`api/projects/${this.$route.params.id}`).then((res) => {
        this.loading = false;
        this.$store.commit('storeProject', res.data.data ? res.data.data : res.data);
        this.$store.dispatch('hideTopProgress');
        this.activeTab = clickedTabIndex;
        if (clickedTabIndex === 1) {
          this.$router.push({ name: 'project-team' }).catch(() => {});
        } else {
          this.$router.push({ name: 'project-backlog' }).catch(() => {});
        }
      }).catch(() => {
        this.loading = false;
        this.$store.dispatch('hideTopProgress');
      });
    },

    getProjectTitle() {
      if (this.$route.params.projectTitle) {
        this.projectTitle = this.$route.params.projectTitle;
      } else if (this.$store.getters.project.id) {
        this.projectTitle = this.$store.getters.project.title;
      } else {
        this.$http.get(`api/projects/${this.$route.params.id}?title=true`).then((res) => {
          this.projectTitle = res.data.title;
        });
      }
    },

    projectUpdated(project) {
      this.projectTitle = project.title;
      this.$store.commit('storeProject', project);
    },
  },
};
</script>

<style scoped>
  .title {
    line-height: 48px !important;
  }

  .title-btn {
    bottom: 2px;
  }
</style>
